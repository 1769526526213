body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hvcentered{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  z-index: 5100;
}
.hcentered{
  margin:auto;
  display:block;
  text-align:center;
}
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}
.fab{
  position: fixed !important;
  z-index:1000;
  bottom: 5px;
  margin: 0 auto !important;
}
.attachment-thumbs-container{
  height: 100%;
}
.attachment-thumbs{
  width: 320px;
  margin:auto;
  display:block;
  object-fit: contain;
}
.media-picker-file{
  opacity: 0;
  position: absolute;
  z-index: -1;
}
